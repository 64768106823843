import styled from 'styled-components'
import { Link } from 'gatsby-plugin-react-i18next'


export const Totem = styled.div`
   margin-top: -70px;
   .swiper-wrapper{
    margin-bottom: 0;
    .swiper-slide{
        .imagem-tv{
            width: 100%;
            display: inline;
        }
        .imagem-tablet{
            width: 100%;
            display: none;
        }
        @media (max-height: 1360px){
            .imagem-tv{
                display: none;
            }
            .imagem-tablet{
                display: flex;
                height: 100vh;
                .gatsby-image-wrapper [data-main-image]{
                    opacity: 1!important;
                }
            }
        }
        .gatsby-image-wrapper img, .gatsby-image-wrapper > div {
            transition: opacity 500ms ease 0s !important;
            height: 100vh;
        }
    }
   }
    .swiper-pagination-bullet.swiper-pagination-bullet-active{
        color: #FFFFFF;
        background: white;
    }
    .swiper-pagination-bullet{
        border: 1px solid #FFFFFF;
        background-color: transparent;
        width: 13px;
        height: 13px;
    }
    .swiper-button-next, .swiper-button-prev{
        border: 1px solid white;
        border-radius: 100%;
        width: 37.17px;
        height: 37.17px;
    }
    .swiper-button-next:after, .swiper-button-prev:after{
        color: white;
        font-size: 22px;
    }
    .swiper-container-horizontal>.swiper-pagination-bullets, .swiper-pagination-custom, .swiper-pagination-fraction{
        bottom: 50px;
    }
    .box-slide{
        position: absolute;
        top: 40%;
        left: 10%;

        h1{
            font-style: italic;
            font-weight: 700;
            font-size: 73.5247px;
            line-height: 63px;
            text-transform: uppercase;
            color: #FFFFFF;
            margin: 0;
            span{
                font-weight: 100;
            }
        }
        p{
            font-style: italic;
            font-size: 38.8432px;
            line-height: 33px;
            color: #FFFFFF;
            margin: 0;
            padding-top: 15px;
            padding-bottom: 30px;
        }
        a{
            border: 1px solid white;
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            line-height: 25px;
            text-align: center;
            text-transform: uppercase;
            color: #FFFFFF;
            text-decoration: none;
            padding: 15px 90px;
            border-radius: 55.2091px;
        }
    }
`

export const NavLinkHome = styled(Link)`

`
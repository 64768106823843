import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import * as S from '../styles';
import SwiperCore, { Autoplay, Navigation, Pagination } from 'swiper';
import Layout from '../../../components/totem/layout';
import SEO from '../../../components/SEO';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { graphql, Link } from 'gatsby';
import PropTypes from 'prop-types';

SwiperCore.use([Navigation, Pagination, Autoplay]);

const Totem = ({
  data: {
    allTotemCarrossel: { edges: totemCarrosseis },
  },
  pageContext: { loja },
}) => {
  useEffect(() => {
    localStorage.setItem("home", true);
    const semCadastro = localStorage.getItem("sem-cadastro")
    if(semCadastro){
      localStorage.removeItem("sem-cadastro");
    }

  }, []);

  const { t } = useTranslation();

  const filteredCarrosseis = totemCarrosseis.filter(({ node: carrossel }) => {
    console.log(carrossel.lojas.includes(loja.slug))
    return carrossel.lojas.includes(loja.slug);
  });

  return (
    <Layout loja={loja}>
      <SEO title={t(`Totem ${loja.nome}`)} description={t('totem')} />
      <S.Totem>
        <Swiper
          spaceBetween={30}
          slidesPerView={1}
          pagination={{ clickable: true }}
          navigation
        >
          {filteredCarrosseis.map(({ node: carrossel }) => (
            <SwiperSlide key={carrossel.id}>
              <S.NavLinkHome
                to={`/totem/${loja.slug}/cadastro`}
                target={carrossel.blank ? '_blank' : ''}
                id={`btn-${carrossel.titulo}`}
                rel="noreferrer"> 
                <img src={carrossel.imagem_tablet} alt={carrossel.titulo} className='imagem-tv'/>
                <img src={carrossel.imagem_tv} alt={carrossel.titulo} className='imagem-tablet'/>
              </S.NavLinkHome>
            </SwiperSlide>
          ))}
        </Swiper>
      </S.Totem>
    </Layout>
  );
};

Totem.propTypes = {
  data: PropTypes.shape({
    allTotemCarrossel: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            id: PropTypes.string.isRequired,
            titulo: PropTypes.string.isRequired,
            blank: PropTypes.bool.isRequired,
            link: PropTypes.string.isRequired,
            texto: PropTypes.string,
            texto_botao: PropTypes.string,
          }),
        })
      ),
    }),
  }).isRequired,
  pageContext: PropTypes.shape({
    loja: PropTypes.shape({
      nome: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default Totem;

export const query = graphql`
  query ($language: String!) {
    allTotemCarrossel(
      sort: { fields: ordem }
      filter: { alternative_id: { ne: 0 } }
    ) {
      edges {
        node {
          id
          titulo
          blank
          link
          texto
          imagem_tablet
          imagem_tv
          lojas
        }
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

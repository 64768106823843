import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'
import { useLocation } from '@reach/router'
import rtrim from 'rtrim'
import { GlobalContext } from '../../Context/global'
import imagemGenerica from '../../assets/images/quiz/quiz-generico.png'
import favicon from '../../assets/images/favicon.png'

const SEO = ({
  description,
  keywords,
  lang,
  meta,
  title,
  image,
  textShare,
  metaTitle,
}) => {
  const { pathname } = useLocation()
  const { language } = useContext(GlobalContext)

  const {
    allSettings: { edges: allSettings },
  } = useStaticQuery(
    graphql`
      query {
        allSettings {
          edges {
            node {
              siteUrl
              title
              metaTitle
              description
              keywords
              urlFacebook
              urlYoutube
              urlInstagram
              pais
            }
          }
        }
      }
    `
  )

  return allSettings
    .filter(({ node }) => node.pais === language)
    .map(({ node }) => {
      const defaultTitle = node?.title
      const metaDescription = description ?? node?.description
      const metaKeywords = node?.keywords ?? keywords
      metaTitle = node?.metaTitle ?? metaTitle
      const defaultImage = image ?? imagemGenerica

      return (
        <Helmet
          key={defaultTitle ?? title}
          htmlAttributes={{
            lang,
          }}
          title={title ?? defaultTitle}
          titleTemplate={
            defaultTitle && defaultTitle !== title
              ? `%s | ${defaultTitle}`
              : null
          }
          meta={[
            {
              name: 'description',
              content: metaDescription ?? textShare,
            },
            {
              name: 'keywords',
              content: metaKeywords,
            },
            {
              name: 'title',
              content: metaTitle,
            },
            {
              property: 'og:title',
              content: title ?? textShare,
            },
            {
              property: 'og:description',
              content: metaDescription ?? textShare,
            },
            {
              property: 'og:type',
              content: 'website',
            },
            {
              property: 'og:image',
              content: node.siteUrl + defaultImage,
            },
            {
              property: 'og:url',
              content: `${node.siteUrl}${pathname}`,
            },
            {
              name: 'twitter:title',
              content: title ?? textShare,
            },
            {
              name: 'twitter:description',
              content: metaDescription ?? textShare,
            },
            {
              name: 'twitter:card',
              content: 'summary',
            },
            {
              name: 'twitter:image',
              content: node.siteUrl + defaultImage,
            },
          ].concat(meta)}
        >
          <link rel="icon" href={favicon} />
        </Helmet>
      )
    })
}

SEO.defaultProps = {
  lang: 'pt-BR',
  meta: [],
  description: '',
  textShare:
    'Entenda como o seu estilo de vida pode definir o seu tipo ideal de corrida',
}

SEO.propTypes = {
  description: PropTypes.string,
  keywords: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  textShare: PropTypes.string,
  image: PropTypes.string,
}

export default SEO
